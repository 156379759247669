/* NewsletterSubscription.css */
  
  .thank-you-message {
    text-align: center;
  }
  
  .subscription-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .input-container {
    display: flex;
    width: 100%;
  }
  
  .email-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin-right: 8px; /* Adjust the margin as needed */
  }
  
  .subscribe-button {
    background-color: #3498db;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
  }
  
  .subscribe-button:hover {
    background-color: #2980b9;
  }

  @media screen and (max-width: 600px) {
    .subscribe-button {
      padding: 8px 12px;
      font-size: 14px;
    }
    .email-input {
      flex: 1;
      padding: 9px;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 12px;
      margin-right: 2px; /* Adjust the margin as needed */
    }
  }
  